import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpHeadersInterceptor } from "./interceptors/headers.interceptor";
import { SharedModule } from "./shared/shared.module"
import { CommonModule } from "@angular/common";
import { AppComponent } from './app.component';
import { environment } from "./../environments/environment";
import { CartlengthService } from '../app/services/cartlength.service';
import { AlphabetSortComponent } from './pages/alphabet-sort/alphabet-sort.component';
import { SocketIoModule } from 'ngx-socket-io';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { InMemoryCacheProvider } from './interceptors/http-response-cache-in-memory';
import { LocalStorageCacheProvider } from './interceptors/http-response-cache-local-storage';
import { StoreModule } from '@ngrx/store';
import { reducer } from 'state/app.reducer';
// import { DeviceDetectorModule } from 'ngx-device-detector';

export let AppInjector: Injector;

const URL = environment.chatUrl
const routes: Routes = [{
  path: "",
  loadChildren: () => import("./start/start.module").then(m => m.StartModule)
}, 
{
  path: "verify/verify-email",
  loadChildren: () => import("./pages/verify-email/verify-email.module").then(m => m.VerifyEmailModule)
},
{
  path: "verify/change-password",
  loadChildren: () => import("./pages/change-password/change-password.module").then(m => m.ChangePasswordModule)
},
{
  path: "verify/age",
  loadChildren: () => import("./pages/age-verify/age-verify.module").then(m => m.AgeVerifyModule)
},

{
  path: "not-found",
  loadChildren: () => import("./pages/not-found/not-found-routing.module").then(m => m.NotFoundRoutingModule)

},


]
@NgModule({
  declarations: [
    AppComponent,
    AlphabetSortComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    HttpClientModule,
    SharedModule,
    SocketIoModule.forRoot({url: URL,options: { transports: ["websocket"], upgrade: false },}),
    StoreModule.forRoot({Store: reducer}),
    // DeviceDetectorModule.forRoot(),
  ],
  providers: [
{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeadersInterceptor,
    multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
 
   { provide: 'googleTagManagerId', useValue: 'GTM-P3S3FQB' },
    CartlengthService,
    InMemoryCacheProvider,
    LocalStorageCacheProvider,
    
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
